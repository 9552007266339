import React, { Component } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Auth } from "aws-amplify";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { StaffInfo } from "./StaffInfo";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { StaffGridActionButtons } from "./StaffGridActionButtons";
import { StaffBoothAdminActionButtons } from "./StaffBoothAdminActionButtons";
import { staffGridOptions } from "./staffGridOptions";
import { DataTable } from "../Shared/DataTable";
import Dialog from "../../Components/Dialog";
import CheckoutFormContainer from "../../Components/Payment/CheckoutFormContainer";
import moment from "moment";
import { EmailTemplate, FormatObject } from "../../Utilities/EmailTemplate";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  center: {
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  staffButton: {
    background: theme.palette.primary.main,
    "&:hover": {
      background: theme.palette.primary.dark,
    },
    color: "#FFF",
    marginBottom: "8px",
    width: "100%",
  },
  passesButton: {
    background: theme.palette.tertiary.main,
    "&:hover": {
      background: theme.palette.tertiary.dark,
    },
    color: "#FFF",
    width: "100%",
  },
});

class StaffList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDetails: null,
      show: null,
      staff: [],
      virtualStaff: [],
      physicalStaff: [],
      boothAdmins: [],
      linkedStaff: [],
      maxStaffVirtual: 0,
      maxStaffPhysical: 0,
      totalDays: 0,
      editing: false,
      deleting: false,
      // typeToAdd: "",
      typeToDelete: "",
      currentStaff: null,
      loaded: false,
      addMorePasses: false,
      qtyPhysical: 0,
      qtyVirtual: 0,
      items: [],
      cartReady: false,
      cartError: false,
    };
  }

  async componentDidMount() {
    if (this.props.event.event !== null) {
      await this.getStaff();
      await this.getEventDetails();
      await this.getShow();
    }
    this.props.history.push("/people");
  }

  async getStaff() {
    try {
      await this.setState({
        staff: [],
        virtualStaff: [],
        physicalStaff: [],
        maxStaffVirtual: 0,
        maxStaffPhysical: 0,
        totalDays: 0,
        editing: false,
        deleting: false,
        deleteInProcess: false,
        typeToDelete: "",
        currentStaff: null,
        loaded: false,
        addMorePasses: false,
        qtyPhysical: 0,
        qtyVirtual: 0,
        items: [],
        cartReady: false,
        cartError: false,
      });

      await new Promise((r) => setTimeout(r, 1000));

      const data = await Auth.currentSession();

      const response1 = await axios({
        method: "get",
        url:
          `/zoho/show-attendees/staff/` +
          this.props.event.event.show_id +
          "/" +
          this.props.event.event.exhibition_id,
        headers: { idtoken: data.idToken.jwtToken },
        params: {
          order_by: "booth_order",
          order_dir: "asc",
        },
      });

      response1.data.data = response1.data.data.sort(
        (a, b) => parseInt(a.booth_order) - parseInt(b.booth_order)
      );

      const virtualStaff = [];
      const physicalStaff = [];
      const boothAdmins = [];
      const linkedStaff = [];

      await response1.data.data.forEach((data) => {
        data.virtual = false;
        data.physical = false;
        // console.log(data);
        if (data.roles.toLowerCase().includes("booth admin")) {
          // console.log(data);
          data.isBoothAdmin = true;
          boothAdmins.push(data);
        } else if (data.days.toLowerCase().includes("day")) {
          // console.log("physical", data);
          data.physical = true;
          physicalStaff.push(data);
        } else if (
          data.days.toLowerCase().includes("virtual")
          // || data.days.toLowerCase().includes("all days")
        ) {
          if (data.booth_visible) {
            data.virtual = true;
            virtualStaff.push(data);
          } else {
            data.linkOnly = true;
            linkedStaff.push(data);
          }
        }
      });

      const response2 = await axios({
        method: "get",
        url:
          `/exhibitions/` +
          this.props.event.event.show_id +
          "/" +
          this.props.user.user.account_id +
          "/" +
          this.props.event.event.exhibition_id,
        headers: { idtoken: data.idToken.jwtToken },
      });

      const response3 = await axios({
        method: "get",
        url: `/shows/` + this.props.event.event.show_id,
        headers: { idtoken: data.idToken.jwtToken },
      });

      let startDate = moment(response3.data[0].start_date);
      let endDate = moment(response3.data[0].end_date);
      let totalDays = endDate.diff(startDate, "days") + 1;

      await this.setState({
        staff: response1.data.data,
        virtualStaff,
        physicalStaff,
        boothAdmins,
        linkedStaff,
        exhibit: response2.data[0],
        maxStaffVirtual: response2.data[0].max_staff,
        maxStaffPhysical: response2.data[0].max_staff_physical,
        availableStaffVirtual: response2.data[0].available_staff_virtual,
        availableStaffPhysical: response2.data[0].available_staff_physical,
        purchasableStaffVirtual: response2.data[0].purchasable_staff_virtual,
        purchasableStaffPhysical: response2.data[0].purchasable_staff_physical,
        usedStaffVirtual: response2.data[0].used_staff_virtual,
        usedStaffPhysical: response2.data[0].used_staff_physical,
        priceStaffVirtual: response2.data[0].price_staff_virtual,
        priceStaffPhysical: response2.data[0].price_staff_physical,
        totalDays: totalDays,
        loaded: true,
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  async getEventDetails() {
    const user = await Auth.currentSession();

    let eventDetails = await axios({
      method: "get",
      url: "events/details/" + this.props.event.event.show_id,
      headers: { idtoken: user.idToken.jwtToken },
    });
    this.setState({ eventDetails: eventDetails.data });
  }

  async getShow() {
    const data = await Auth.currentSession();

    let myShow = await axios({
      method: "get",
      url: `/shows/${this.props.event.event.show_id}`,
      headers: { idtoken: data.idToken.jwtToken },
    });
    myShow = myShow.data[0];
    await this.setState({ show: myShow });
  }

  handleEditingChange(e, type) {
    this.setState({
      editing: e,
      // typeToAdd: type,
    });
  }

  async handleDeletingChange(e, type) {
    this.setState({
      deleting: e,
      typeToDelete: type,
    });
  }

  handleDeletingCancel() {
    this.setState({
      deleting: false,
    });
  }

  handleAddMorePassesCancel() {
    if (window.location.pathname.includes("addpasses-success")) {
      window.location.reload();
    }
    this.setState({
      addMorePasses: false,
      cartReady: false,
      qtyPhysical: 0,
      qtyVirtual: 0,
      cartError: "",
    });
  }
  handlePassSelect(e, type) {
    switch (type) {
      case "physical":
        this.setState({ qtyPhysical: e.target.value, cartError: "" });
        break;
      case "virtual":
        this.setState({ qtyVirtual: e.target.value, cartError: "" });
        break;
      default:
        break;
    }
  }

  async handleEditClose() {
    // await new Promise((r) => setTimeout(r, 2000)); // timeout set in getStaff
    await this.getStaff(); // doesn't always display new info
    // await window.location.reload(); // makes screen flash but guarantees fresh data
    // await this.setState({
    //   editing: false,
    //   currentStaff: null,
    // });
  }

  updateCart() {
    if (!this.state.qtyPhysical && !this.state.qtyVirtual) {
      this.setState({
        cartError: "No passes selected. Please select a quantity.",
      });
      return;
    }
    const items = [];
    let priceStaffPhysical = this.state.priceStaffPhysical * 100; // *100 necessary as price is in cents not dollars
    let physicalPasses = {
      productName: "physical passes",
      qty: this.state.qtyPhysical,
      price: priceStaffPhysical,
    };
    items.push(physicalPasses);
    let priceStaffVirtual = this.state.priceStaffVirtual * 100; // *100 necessary as price is in cents not dollars
    let virtualPasses = {
      productName: "virtual passes",
      qty: this.state.qtyVirtual,
      price: priceStaffVirtual,
    };
    items.push(virtualPasses);
    this.setState({ items, cartReady: true });
  }

  async handleDeletingConfirm(e) {
    await this.setState({ deleteInProcess: true });
    const user = await Auth.currentSession();

    const response = await axios({
      method: "delete",
      url: `/zoho/show-attendees/staff/${this.state.currentStaff.attendee_id}`,
      headers: { idtoken: user.idToken.jwtToken },
    });

    let formatEmail = async () => {
      let subject = `${this.props.event.event.show_name} - Staff un-assigned: ${this.state.currentStaff.first_name} ${this.state.currentStaff.last_name}`;

      let userInformation = {
        name: `${this.state.currentStaff.first_name} ${this.state.currentStaff.last_name}`,
        email: this.state.currentStaff.email,
        jobTitle: this.state.currentStaff.title,
        company: this.props.event.event.account_name,
        department: this.state.currentStaff.department,
        linkedInUrl: this.state.currentStaff.linked_in_url,
        expertiseArea: this.state.currentStaff.expertise_area,
        boothOrder: this.state.currentStaff.booth_order,
        profilePicture: this.state.currentStaff.photo_url,
        attendance: this.state.currentStaff.days,
        roles: this.state.currentStaff.roles,
      };

      let body1 = `Your booth staff has successfully been un-assigned for ${this.props.event.event.show_name}. Below is the information from the deleted record:`;
      let body2 = FormatObject(userInformation);
      // 'If you would now like to submit an abstract for consideration, please revisit ${this.props.event.data.landing_page_url} and click the Abstract Submission Link.';
      // let body3 = `You can access the virtual environment at ${this.props.event.data.landing_page_url}`;
      let body3 = `This edit was made by ${this.props.event.event.first_name} ${this.props.event.event.last_name}: ${this.props.event.event.email}`;
      let body4 =
        "If you have any questions, please contact Deb Hunter (dhunter@planetconnect.com).";
      let body5 = "Enjoy the event!";

      let body = `${body1}<br/>${body2}<br/>${body3}<br/>${body4}<br/>${body5}`;

      let emailObject = {
        to: [
          `${this.props.user.user.first_name} ${this.props.user.user.last_name} <${user.idToken.payload.email}>`,
          `${this.state.currentStaff.first_name} ${this.state.currentStaff.last_name} <${this.state.currentStaff.email}>`,
          "registration@planetconnect.com",
          "rjanelli@planetconnect.com",
        ],
        subject: subject,
        body: body,
        title: userInformation.name,
        fromName: `${this.props.event.event.show_name} Registration`,
        banner: this.state.eventDetails.banner_location,
      };

      let bodyOfEmail = EmailTemplate(emailObject);
      emailObject.body = bodyOfEmail;
      await axios({
        method: "POST",
        url: `/mail`,
        data: emailObject,
      });
    };

    await formatEmail();

    const response2 = await axios({
      method: "get",
      url:
        `/exhibitions/` +
        this.state.currentStaff.show_id +
        "/" +
        this.state.currentStaff.account_id +
        "/" +
        this.state.currentStaff.exhibition_id,
      headers: { idtoken: user.idToken.jwtToken },
    });

    let currentAvailablePhysical = response2.data[0].available_staff_physical;
    let currentAvailableVirtual = response2.data[0].available_staff_virtual;
    let currentUsedPhysical = response2.data[0].used_staff_physical;
    let currentUsedVirtual = response2.data[0].used_staff_virtual;
    let attendanceArray = this.state.currentStaff.days.split(", ");
    let physicalPassesToRemove = 0;
    let virtualPassesToRemove = 0;
    let physicalOnlyOverride = false;
    attendanceArray.forEach((day) => {
      if (day === "Virtual") {
        if (this.state.currentStaff.booth_visible) {
          virtualPassesToRemove = 1;
        }
      } else {
        physicalPassesToRemove += 1;
        physicalOnlyOverride = true;
      }
    });
    if (physicalOnlyOverride) {
      virtualPassesToRemove = 0;
    }

    let updatedExhibitPasses = {
      id: this.state.currentStaff.exhibition_id,
      available_physical_staff: currentAvailablePhysical,
      available_virtual_staff: currentAvailableVirtual,
      used_physical_staff: currentUsedPhysical - physicalPassesToRemove,
      used_virtual_staff: currentUsedVirtual - virtualPassesToRemove,
    };
    await axios({
      method: "put",
      url: `/zoho/exhibitions/${this.state.currentStaff.show_id}/${this.state.currentStaff.account_id}/${this.state.currentStaff.exhibition_id}/passes`,
      headers: { idtoken: user.idToken.jwtToken },
      data: updatedExhibitPasses,
    });

    if (response.data) {
      await this.setState({
        deleting: false,
        deleteInProcess: false,
        typeToDelete: "",
        currentStaff: null,
      });

      await new Promise((r) => setTimeout(r, 2000));

      await this.getStaff(); // doesn't always show latest data
      // await window.location.reload();
    } else {
      alert("Your attendee could not be deleted");
    }
  }

  setCurrentStaff(e) {
    this.setState({
      currentStaff: e,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.setState({
        editing: false,
        currentStaff: null,
      });
      return false;
    }
  }

  render() {
    const { classes } = this.props;

    let eventIsArchived = false;
    if (this.props.archived.archived) {
      if (!this.props.user.user.email.includes("rjanelli@planetconnect.com")) {
        eventIsArchived = true;
      }
    }

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    let bodyContent;

    let loadingGraphic = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <CircularProgress color="inherit" />
        <Typography variant="h4" color="textPrimary">
          Loading...
        </Typography>
      </Grid>
    );
    let noResultsFound = (
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <br />
        <br />
        <Typography className={classes.center} variant="h5">
          You currently have no staff for this event
        </Typography>
      </Grid>
    );

    if (!this.state.staff.length) {
      if (this.state.loaded) {
        bodyContent = noResultsFound;
      } else {
        bodyContent = loadingGraphic;
      }
    } else {
      if (this.state.editing) {
        bodyContent = (
          <StaffInfo
            exhibitionId={this.props.event.event.exhibition_id}
            currentStaff={this.state.currentStaff}
            showId={this.props.event.event.show_id}
            accountId={this.props.user.user.account_id}
            totalDays={this.state.totalDays}
            handleEditClose={this.handleEditClose.bind(this)}
            availablePhysicalPasses={
              this.state.availableStaffPhysical - this.state.usedStaffPhysical
            }
            availableVirtualPasses={
              this.state.availableStaffVirtual - this.state.usedStaffVirtual
            }
            originalAvailablePhysicalPasses={this.state.availableStaffPhysical}
            originalAvailableVirtualPasses={this.state.availableStaffVirtual}
            usedStaffPhysical={this.state.usedStaffPhysical}
            usedStaffVirtual={this.state.usedStaffVirtual}
            sidebar={this.props.sidebar}
            event={this.props.event.event}
            user={this.props.user.user}
            eventDetails={this.state.eventDetails}
            eventIsArchived={eventIsArchived}
            show={this.state.show}
            // typeToAdd={this.state.typeToAdd}
          />
        );
      } else {
        bodyContent = (
          <>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <Typography className={classes.center} variant="h5">
                    Booth Admin
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    The administrator(s) of your booth responsible for setting
                    info about the company, assigning staff, choosing products
                    to showcase and properly linking activities.
                  </Typography>
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Divider className={classes.divider} />
              <DataTable
                initialGridOptions={staffGridOptions}
                initialRowData={this.state.boothAdmins}
                actionButtons={StaffBoothAdminActionButtons}
                type="attendee"
                // setDeleting={(e) => this.handleDeletingChange(e, "physical")}
                setEditing={(e) => this.handleEditingChange(e)}
                setCurrentPage={(e) => this.setCurrentStaff(e)}
                eventIsArchived={eventIsArchived}
              />
            </Paper>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <Typography className={classes.center} variant="h5">
                    On-Site Staff
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="h6"
                    color={
                      this.state.usedStaffPhysical >
                      this.state.availableStaffPhysical
                        ? "error"
                        : "textPrimary"
                    }
                  >
                    {`${
                      this.state.usedStaffPhysical
                        ? this.state.usedStaffPhysical
                        : 0
                    } of ${
                      this.state.availableStaffPhysical
                        ? this.state.availableStaffPhysical
                        : 0
                    } Passes Used`}
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    Up to {this.state.maxStaffPhysical} On-Site passes available
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    {this.state.purchasableStaffPhysical} On-Site Staff pass
                    {this.state.purchasableStaffPhysical !== 1 ? "es " : " "}
                    available for purchase for ${
                      this.state.priceStaffPhysical
                    }{" "}
                    per day
                  </Typography>
                  {this.state.usedStaffPhysical >
                  this.state.availableStaffPhysical ? (
                    <Typography
                      className={classes.center}
                      variant="body2"
                      color="error"
                    >
                      Maximum On-Site staff has been exceeded. Please remove
                      staff until you are within the allotted amount. If you
                      have questions, please utilize our support chat window in
                      the bottom left corner.
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={2} align="right" style={{ marginTop: "auto" }}>
                  {!this.state.editing && !eventIsArchived && (
                    <Button
                      onClick={() => this.handleEditingChange(true)}
                      variant="contained"
                      // color="secondary"
                      className={classes.staffButton}
                    >
                      Add Staff
                    </Button>
                  )}
                  <Button
                    onClick={() =>
                      this.setState({
                        addMorePasses: true,
                      })
                    }
                    variant="contained"
                    // color="primary"
                    className={classes.passesButton}
                  >
                    Buy Additional <br />
                    Passes
                  </Button>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <DataTable
                initialGridOptions={staffGridOptions}
                initialRowData={this.state.physicalStaff}
                actionButtons={StaffGridActionButtons}
                type="attendee"
                setDeleting={(e) => this.handleDeletingChange(e, "physical")}
                setEditing={(e) => this.handleEditingChange(e)}
                setCurrentPage={(e) => this.setCurrentStaff(e)}
                eventIsArchived={eventIsArchived}
              />
            </Paper>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <Typography className={classes.center} variant="h5">
                    Virtual Staff
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="h6"
                    color={
                      this.state.usedStaffVirtual >
                      this.state.availableStaffVirtual
                        ? "error"
                        : "textPrimary"
                    }
                  >
                    {`${
                      this.state.usedStaffVirtual
                        ? this.state.usedStaffVirtual
                        : 0
                    } of ${
                      this.state.availableStaffVirtual
                        ? this.state.availableStaffVirtual
                        : 0
                    } Passes Used`}
                  </Typography>
                  {this.state.virtualStaff.length >
                  this.state.maxStaffVirtual ? (
                    <Typography
                      className={classes.center}
                      variant="body2"
                      color="error"
                    >
                      Maximum Virtual staff has been exceeded. Staff displayed
                      in booth are only those with Visible in Booth enabled. If
                      you have questions, please utilize our support chat window
                      in the bottom left corner.
                    </Typography>
                  ) : null}
                  <Typography
                    className={classes.center}
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    Up to {this.state.maxStaffVirtual} Virtual passes available
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="subtitle1"
                    color="textPrimary"
                  >
                    {this.state.purchasableStaffVirtual} Virtual Staff pass
                    {this.state.purchasableStaffVirtual !== 1 ? "es " : " "}
                    available for purchase for ${
                      this.state.priceStaffVirtual
                    }{" "}
                    per day
                  </Typography>
                </Grid>
                <Grid item xs={2} align="right" style={{ marginTop: "auto" }}>
                  {!this.state.editing && !eventIsArchived && (
                    <Button
                      onClick={() => this.handleEditingChange(true)}
                      variant="contained"
                      // color="secondary"
                      className={classes.staffButton}
                    >
                      Add Staff
                    </Button>
                  )}
                  <Button
                    onClick={() =>
                      this.setState({
                        addMorePasses: true,
                      })
                    }
                    variant="contained"
                    // color="primary"
                    className={classes.passesButton}
                  >
                    Buy Additional <br /> Passes
                  </Button>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <DataTable
                initialGridOptions={staffGridOptions}
                initialRowData={this.state.virtualStaff}
                actionButtons={StaffGridActionButtons}
                type="attendee"
                setDeleting={(e) => this.handleDeletingChange(e, "virtual")}
                setEditing={(e) => this.handleEditingChange(e)}
                setCurrentPage={(e) => this.setCurrentStaff(e)}
                eventIsArchived={eventIsArchived}
              />
            </Paper>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <Typography className={classes.center} variant="h5">
                    Linked Staff
                  </Typography>
                  <Typography
                    className={classes.center}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    Any staff here are linked to your booth but will not display
                    in the virtual environment or count against any passes.
                    Activities of linked staff will populate in your booth.
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {!this.state.editing && !eventIsArchived && (
                    <Button
                      onClick={() => this.handleEditingChange(true)}
                      variant="contained"
                      // color="secondary"
                      className={classes.staffButton}
                    >
                      Add Staff
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <DataTable
                initialGridOptions={staffGridOptions}
                initialRowData={this.state.linkedStaff}
                actionButtons={StaffGridActionButtons}
                type="attendee"
                setDeleting={(e) => this.handleDeletingChange(e, "virtual")}
                // TODO this setDeleting may need logic updates
                setEditing={(e) => this.handleEditingChange(e)}
                setCurrentPage={(e) => this.setCurrentStaff(e)}
                eventIsArchived={eventIsArchived}
              />
            </Paper>
          </>
        );
      }
    }

    let purchasePhysicalPassesDropdown = [];
    for (let i = 0; i <= this.state.purchasableStaffPhysical; i++) {
      purchasePhysicalPassesDropdown.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }
    let purchaseVirtualPassesDropdown = [];
    for (let i = 0; i <= this.state.purchasableStaffVirtual; i++) {
      purchaseVirtualPassesDropdown.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }

    let passesForSale = (
      <>
        <Typography style={{ textAlign: "center" }}>
          How many passes would you like to buy?
        </Typography>
        <br />
        <Divider className={classes.divider} />
        <Typography variant="h6">Physical Passes</Typography>
        {this.state.purchasableStaffPhysical ? (
          <>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.qtyPhysical}
              onChange={(e) => this.handlePassSelect(e, "physical")}
            >
              {purchasePhysicalPassesDropdown}
            </Select>
            <Typography style={{ display: "inline" }}>
              of {this.state.purchasableStaffPhysical} available.
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              No more Physical Passes avilable for purchase.
            </Typography>
          </>
        )}
        <Divider className={classes.divider} />
        <Typography variant="h6">Virtual Passes</Typography>
        {this.state.purchasableStaffVirtual ? (
          <>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.qtyVirtual}
              onChange={(e) => this.handlePassSelect(e, "virtual")}
            >
              {purchaseVirtualPassesDropdown}
            </Select>
            <Typography style={{ display: "inline" }}>
              of {this.state.purchasableStaffVirtual} available.
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              No more Virtual Passes avilable for purchase
            </Typography>
          </>
        )}
        <Divider className={classes.divider} />
        {this.state.cartError && (
          <Typography color="error">{this.state.cartError}</Typography>
        )}
      </>
    );

    return (
      <>
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={2}>
                  {this.state.editing ? (
                    <Button
                      onClick={() => this.handleEditClose()}
                      variant="contained"
                      color="primary"
                    >
                      Back
                    </Button>
                  ) : null}
                </Grid>
                <Grid item xs={8}>
                  <Typography
                    className={classes.center}
                    variant="h4"
                    color="textPrimary"
                  >
                    Staff
                  </Typography>
                  {/* <Typography 
                                    className={classes.center} 
                                    variant='h6' 
                                    color={this.state.staff.length > this.state.maxStaffVirtual ? 'error' :'textPrimary'} 
                                >
                                    {this.state.staff.length && this.state.maxStaffVirtual ? `Staff ${this.state.staff.length} / ${this.state.maxStaffVirtual}` : `Staff 0 / 0`} Maximum
                                </Typography> */}
                  <Typography
                    className={classes.center}
                    variant="subtitle2"
                    color="textPrimary"
                  >
                    You may assign your staff as On-Site or Virtual and select
                    which days they will be attending using the On-site Booth
                    Staff or Visible in Virtual Booth toggle on that staff.
                    Please note additional charges may apply. <br />
                    Please use the Order in Booth field to rearrange your staff.
                  </Typography>
                  {eventIsArchived && (
                    <Typography
                      className={classes.center}
                      variant="body1"
                      color="error"
                    >
                      <br />
                      Event is archived and cannot be edited.
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  align="right"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                >
                  {/* placed in specific sections below */}
                  {/* {!this.state.editing && !eventIsArchived && (
                    <Button
                      onClick={() => this.handleEditingChange(true)}
                      variant="contained"
                      color="secondary"
                    >
                      Add Staff
                    </Button>
                  )} */}
                </Grid>
              </Grid>
            </Paper>
            {bodyContent}
          </Grid>
        </Grid>
        <Dialog
          open={this.state.deleting}
          handleClose={() => this.handleDeletingCancel()}
        >
          {this.state.deleteInProcess ? (
            <Typography style={{ margin: "60px" }}>
              Un-assigning staff...
            </Typography>
          ) : (
            <>
              <Typography>Are you sure you want to un-assign?</Typography>
              <br />
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => this.handleDeletingConfirm()}
              >
                Yes
              </Button>
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.handleDeletingCancel()}
              >
                No
              </Button>
            </>
          )}
        </Dialog>
        <Dialog
          open={this.state.addMorePasses}
          handleClose={() => this.handleAddMorePassesCancel()}
        >
          {!this.state.cartReady ? passesForSale : null}
          {this.state.cartReady ? (
            <CheckoutFormContainer
              items={this.state.items}
              availableStaffVirtual={this.state.availableStaffVirtual}
              availableStaffPhysical={this.state.availableStaffPhysical}
              usedStaffVirtual={this.state.usedStaffVirtual}
              usedStaffPhysical={this.state.usedStaffPhysical}
              showId={this.props.event.event.show_id}
              accountId={this.props.user.user.account_id}
              exhibitionId={this.props.event.event.exhibition_id}
              exhibit={this.state.exhibit}
              showName={this.props.event.event.show_name}
              contactName={
                this.props.event.event.first_name +
                " " +
                this.props.event.event.last_name
              }
            />
          ) : (
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => this.updateCart()}
            >
              Buy Passes
            </Button>
          )}
          <br />
          <br />
          {window.location.pathname.includes("addpasses-success") ? null : (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => this.handleAddMorePassesCancel()}
            >
              Cancel
            </Button>
          )}
        </Dialog>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    sidebar: state.sidebar,
    archived: state.archived,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(StaffList));
