// TEST 3
// FROM https://stripe.com/docs/payments/quickstart

import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import axios from "axios";
import { Auth } from "aws-amplify";

import { EmailTemplate } from "../../Utilities/EmailTemplate";

import { useHistory } from "react-router-dom";

const updatePasses = async (exhibitData, setSuccess, history) => {
  try {
    const user = await Auth.currentSession();
    let physicalPassesToBuy = 0;
    let virtualPassesToBuy = 0;
    let physicalPrice = 0;
    let virtualPrice = 0;
    let total = 0;

    exhibitData.items.forEach((item) => {
      switch (item.productName) {
        case "physical passes":
          physicalPassesToBuy += item.qty;
          physicalPrice = item.price / 100;
          total += item.qty * item.price;

          break;
        case "virtual passes":
          virtualPassesToBuy += item.qty;
          virtualPrice = item.price / 100;
          total += item.qty * item.price;

          break;
        default:
          break;
      }
    });
    total = total / 100; // convert to dollars from cents

    let updatedExhibitPasses = {
      id: exhibitData.exhibitionId,
      available_physical_staff:
        exhibitData.availableStaffPhysical + physicalPassesToBuy,
      available_virtual_staff:
        exhibitData.availableStaffVirtual + virtualPassesToBuy,
      used_physical_staff: exhibitData.usedStaffPhysical,
      used_virtual_staff: exhibitData.usedStaffVirtual,
    };

    await axios({
      method: "put",
      url: `/zoho/exhibitions/${exhibitData.showId}/${exhibitData.accountId}/${exhibitData.exhibitionId}/passes`,
      headers: { idtoken: user.idToken.jwtToken },
      data: updatedExhibitPasses,
    });

    let eventDetails = await axios({
      method: "get",
      url: "events/details/" + exhibitData.showId,
      headers: { idtoken: user.idToken.jwtToken },
    });
    eventDetails = eventDetails.data;

    let subjectPayment = `${exhibitData.showName} - Booth Pass Purchase Confirmation`;
    let bodyPayment = `Event: <br/>
    ${exhibitData.showName} <br/><br/>
    Purchaser: <br/>
    ${exhibitData.contactName}<br/>
    ${user.idToken.payload.email}<br/>
    ${exhibitData.exhibit.account_alias} <br/><br/>
    In-Person Booth Passes: <br/>
    ${physicalPassesToBuy} Booth Passes @ $${physicalPrice} <br/><br/>
    Virtual Environment Booth Passes: <br/>
    ${virtualPassesToBuy} Booth Passes @ $${virtualPrice} <br/><br/>
    Total Purchase: $${total} paid by credit card.`;

    let emailObjectPaymentInfo = {
      to: [
        `${exhibitData.contactName} <${user.idToken.payload.email}>`,
        "registration@planetconnect.com",
        "rjanelli@planetconnect.com",
        "lhathaway@planetconnect.com",
        "rhb@planetconnect.com",
      ],
      subject: subjectPayment,
      body: bodyPayment,
      title: subjectPayment,
      fromName: `${exhibitData.showName} Registration`,
      banner: eventDetails.banner_location,
    };
    let bodyOfEmailPayment = EmailTemplate(emailObjectPaymentInfo);
    emailObjectPaymentInfo.body = bodyOfEmailPayment;
    await axios({
      method: "POST",
      url: `/mail`,
      data: emailObjectPaymentInfo,
    });
    history.push("/people/addpasses-success");
    setSuccess(true);
  } catch (error) {
    console.log(error);
  }
};

const displayTotal = (items) => {
  let total = 0;
  let eachItem = [];
  items.forEach((item, index) => {
    total += item.qty * item.price;
    let modifiedProductName = item.productName.split(" ")[0];
    let capitalizedProductName =
      modifiedProductName.charAt(0).toUpperCase() +
      modifiedProductName.slice(1);
    eachItem.push(
      <Typography key={index}>
        Type: {capitalizedProductName} - Quantity: {item.qty} - Price: $
        {item.price / 100}
      </Typography>
    );
  });
  total = total / 100;
  return (
    <div
      style={{
        background: "white",
        borderRadius: "8px",
        padding: "8px 8px 0px 8px",
      }}
    >
      {eachItem}
      <Typography variant="h6">Total: ${total}</Typography>
      {/* <Divider style={{ marginTop: "4px", marginBottom: "4px" }} /> */}
    </div>
  );
};

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  let history = useHistory();

  const [message, setMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [history, stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    try {
      let error;
      await stripe
        .confirmPayment({
          // const response = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: "https://admin.planetconnect.com/people/people/",
          },
          redirect: "if_required",
        })
        .then(function (result) {
          if (result.error) {
            error = result.error;
          } else {
            updatePasses(props.props, setSuccess, history);
          }
        });
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      // As redirect is set to 'if_required', 'return_url' is not used
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const buyForm = (
    <form
      id="payment-form"
      onSubmit={handleSubmit}
      style={{ background: "#efefef", borderRadius: "4px", padding: "8px" }}
    >
      {displayTotal(props.props.items)}
      <br />
      <PaymentElement id="payment-element" />
      {/* <button disabled={isLoading || !stripe || !elements} id="submit"> */}
      <br />
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        disabled={isLoading || !stripe || !elements}
        id="submit"
        type="submit"
      >
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </Button>
      {/* </button> */}
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );

  const successDisplay = (
    <>
      <Typography variant="h6">
        Your payment was a success and account passes have been updated.
      </Typography>
      <br />
      <br />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => window.location.reload()}
      >
        X Close
      </Button>
    </>
  );

  return success ? successDisplay : buyForm;
}
