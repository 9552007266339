import * as React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import trackAction from "../../Store/Actions/tracker";
import getValidUrl from "../../Utilities/getValidUrl";
import moment from "moment";

// Configure Redux
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  clickable: {
    // cursor: "pointer",
  },
  root: {
    backgroundColor: "#FFF",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
  },
  logoGrid: {
    backgroundColor: "#FFF",
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 1, 0, 1),
    alignSelf: "center",
  },
  logo: {
    width: "100%",
    height: "140px",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeatX: "no-repeat",
    backgroundRepeatY: "no-repeat",
  },
  featureProductRoot: {
    paddingLeft: theme.spacing(1),
  },
  featurProductTitleGrid: {
    color: "black",
    backgroundColor: "#FFF",
    borderRadius: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  featureProductGridItem: {
    cursor: "pointer",
    height: "100%",
  },
  featureProductImage: {
    width: "96%",
    height: "112px",
    backgroundColor: "#FFF",
    border: "2px solid #616161",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeatX: "no-repeat",
    backgroundRepeatY: "no-repeat",
  },
  featureAbstractGridItem: {
    cursor: "pointer",
    padding: theme.spacing(0, 0.5),
  },
  featureAbstractText: {
    color: "black",
    backgroundColor: "#FFF",
    borderRadius: theme.spacing(0.5),
    border: "2px solid #616161",
  },
}));

function BoothImageBooth(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [colorScheme, setColorScheme] = useState({
    primary_color: "",
    primary_colorDark: "",
    primary_colorLight: "",
    secondary_color: "",
  });

  const LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] === "#") {
      col = col.slice(1);
      usePound = true;
    }

    var R = parseInt(col.substring(0, 2), 16);
    var G = parseInt(col.substring(2, 4), 16);
    var B = parseInt(col.substring(4, 6), 16);
    // to make the colour less bright than the input
    // change the following three "+" symbols to "-"
    R = R + amt;
    G = G + amt;
    B = B + amt;

    if (R > 255) R = 255;
    else if (R < 0) R = 0;

    if (G > 255) G = 255;
    else if (G < 0) G = 0;

    if (B > 255) B = 255;
    else if (B < 0) B = 0;

    var RR =
      R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
    var GG =
      G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
    var BB =
      B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

    return (usePound ? "#" : "") + RR + GG + BB;
  };

  useEffect(() => {
    let primary;
    let secondary;
    if (props.exhibit.primary_color) {
      primary = props.exhibit.primary_color;
    } else {
      primary = "#6f1a1d";
    }
    if (props.exhibit.secondary_color) {
      secondary = props.exhibit.secondary_color;
    } else {
      secondary = "#1b5ebe";
    }
    let dark = LightenDarkenColor(primary, -40);
    let light = LightenDarkenColor(primary, 40);
    setColorScheme({
      primary_color: primary,
      primary_colorDark: dark,
      primary_colorLight: light,
      secondary_color: secondary,
    });
  }, [props.exhibit.primary_color, props.exhibit.secondary_color]);

  let featureProductClick = (event, productUrl, productId, productName) => {
    if (productUrl) {
      window.open(getValidUrl(productUrl), "_blank");
    }
    props.handleTabChange(event, 1, productId);
    const clickEvent = {
      action: "Clicked",
      resource_type: "Product / Featured",
      resource_id: productId,
      url_visited: "/exhibithall",
      resource_account_id: props.exhibit.account_id,
      resource_account_name: props.exhibit.account_alias,
      resource_name: productName,
    };
    // dispatch(trackAction(clickEvent));
  };

  let featureAbstractClick = (event, abstractUrl, abstractId, abstractName) => {
    // if (abstractUrl) {
    //   window.open(getValidUrl(abstractUrl), '_blank');
    // }
    props.handleTabChange(event, 2, abstractId);
    const clickEvent = {
      action: "Clicked",
      resource_type: "Activity / Featured",
      resource_id: abstractId,
      url_visited: "/exhibithall",
      resource_account_id: props.exhibit.account_id,
      resource_account_name: props.exhibit.account_alias,
      resource_name: abstractName,
    };
    // dispatch(trackAction(clickEvent));
  };

  let productLimit = 1;
  let featuredProducts;
  featuredProducts = props.products.map((product, index) => {
    if (index <= productLimit) {
      let backgroundImageUrl;
      if (product.product_image_url) {
        backgroundImageUrl = `url('${product.product_image_url}')`;
      } else {
        backgroundImageUrl = "";
      }
      // console.log(backgroundImageUrl);
      return (
        <Grid
          item
          xs={6}
          key={`Product ${index}`}
          className={classes.featureProductGridItem}
          onClick={(event) => {
            featureProductClick(
              event,
              product.product_url,
              product.product_id,
              product.name
            );
          }}
        >
          <div
            className={classes.featureProductImage}
            style={{
              backgroundImage: backgroundImageUrl,
              display: "inline-flex",
              overflow: "hidden",
              borderRadius: !backgroundImageUrl ? "4px" : "4px 4px 0px 0px",
            }}
          >
            <Typography
              variant="subtitle2"
              style={{
                color: "black",
                margin: "auto",
                visibility: backgroundImageUrl && "hidden",
              }}
            >
              {product.name}
            </Typography>
          </div>
          {backgroundImageUrl && (
            <Typography
              variant="subtitle2"
              style={{
                width: "96%",
                backgroundColor: "#FFF",
                border: "2px solid #616161",
                borderRadius: "0 0 4px 4px",
                borderStyle: "none solid solid solid",
                margin: "auto",
                // visibility: !backgroundImageUrl && 'hidden',
              }}
              color="textPrimary"
            >
              {product.name}
            </Typography>
          )}
        </Grid>
      );
    } else {
      return null;
    }
  });
  featuredProducts.length = productLimit + 1;

  let activitiesIndex = 0;
  let indexFound = false;
  let activitiesLimit = 1;
  let featuredActivities;

  featuredActivities = props.abstracts?.map((abstract, index) => {
    let date = moment(abstract.date).format("YYYY-MM-DD");
    let time = moment(abstract.end_time).add(15, "minutes").format("HH:mm:ss");
    let timeNow = moment().format("HH:mm:ss");
    let dateNow = moment().format("YYYY-MM-DD");

    switch (true) {
      case date < dateNow:
        // date is in the past
        break;
      case date === dateNow:
        // date is today
        if (time < timeNow) {
          // time is in the past
        } else {
          // time is in the future
          if (!indexFound) {
            activitiesIndex = index;
            indexFound = true;
          }
        }
        break;
      default:
        // time is in the future
        if (!indexFound) {
          activitiesIndex = index;
          indexFound = true;
        }
        break;
    }

    return (
      <Grid
        item
        xs={12}
        key={abstract.title + index}
        className={classes.featureAbstractGridItem}
        onClick={(event) => {
          featureAbstractClick(
            event,
            abstract.presentation_link,
            abstract.abstract_id,
            abstract.title
          );
        }}
      >
        <Typography
          key={abstract.title + index}
          className={classes.featureAbstractText}
          variant="subtitle2"
        >
          {abstract.title}
        </Typography>
      </Grid>
    );
  });

  function removeAllBefore(array, number) {
    for (let i = 0; i < number; i++) {
      array.shift();
    }
    return array;
  }

  if (
    activitiesIndex + 1 >= activitiesLimit ||
    featuredActivities?.length - activitiesIndex + 1 >=
      featuredActivities?.length - activitiesLimit
  ) {
    featuredActivities = removeAllBefore(featuredActivities, activitiesIndex);
  }
  console.log(featuredActivities);
  if (featuredActivities.length !== 0) {
    featuredActivities.length = activitiesLimit;
  }

  return (
    <Grid
      container
      justifyContent="center"
      className={classes.root}
      style={{
        backgroundImage: `linear-gradient(to bottom right, ${colorScheme?.primary_color}, ${colorScheme?.secondary_color})`,
        border: `2px solid ${colorScheme?.primary_color}`,
        borderRadius: "4px",
      }}
    >
      <Grid item xs={4} className={classes.logoGrid}>
        <div
          className={classes.logo}
          style={{
            backgroundImage: `url('${props.exhibit.logo_address}')`,
            display: "inline-flex",
            overflow: "hidden",
          }}
        />
      </Grid>
      <Grid
        item
        xs={8}
        container
        justifyContent="center"
        className={classes.featureProductRoot}
      >
        <Grid item xs={12} className={classes.featurProductTitleGrid}>
          <Typography variant="h6" color="textPrimary">
            Featured Products
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            margin: "0px -4px",
            width: "calc(100% + 8px)",
          }}
        >
          {featuredProducts}
        </Grid>
      </Grid>
      {featuredActivities.length !== 0 && (
        <>
          <Grid
            item
            xs={12}
            className={classes.featurProductTitleGrid}
            style={{ marginTop: "8px" }}
          >
            <Typography variant="h6" color="textPrimary">
              Featured Activities
            </Typography>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{
              margin: "-4px",
              width: "calc(100% + 8px)",
              // height: '82%',
            }}
          >
            {featuredActivities}
          </Grid>
        </>
      )}
    </Grid>
  );
}
export default BoothImageBooth;
