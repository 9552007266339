import React, { useState } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import { Formik, Form, Field, useField } from "formik";
import TextField from "@material-ui/core/TextField";
import { FileUploader } from "../Shared/FileUploader";
import Dialog from "../../Components/Dialog";
import { EmailTemplate, FormatObject } from "../../Utilities/EmailTemplate";
import getValidUrl from "../../Utilities/getValidUrl";

const useStyles = makeStyles((theme) => ({
  // root: {
  //     display: 'flex',
  //     flexWrap: 'wrap',
  // },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  form: {
    // padding: theme.spacing(1)
    marginBottom: theme.spacing(12),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  submitGridItem: {
    position: "fixed",
    bottom: "60px",
    // left: (props) => (props.sidebar?.open ? "120px" : "2px"),
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    background: theme.palette.background.main,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
  },
  submitButton: {
    color: "white",
    padding: theme.spacing(2, 8),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
}));

const ProductInfoField = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      setValue(e.target.value + "\r\n");
    }
  };

  if (field.value === null) {
    field.value = "";
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Field
            className="text-input"
            component={TextField}
            margin="dense"
            variant="outlined"
            fullWidth
            onKeyDown={props.minRows ? handleOnKeyDown : null}
            {...field}
            {...props}
          />
          {meta.touched && meta.error ? (
            <div className="error" style={{ color: "red" }}>
              {meta.error}
            </div>
          ) : null}
        </Grid>
        <Grid
          item
          xs={1}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Tooltip title={props.tooltip} placement="right">
            <InfoIcon />
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

const ProductSwitchField = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  if (field.value === null) {
    field.value = "";
  }

  const handleVisualToggle = () => {
    setValue(!field.value);
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Field
          component={Switch}
          checked={field.value}
          onChange={handleVisualToggle}
          color="primary"
          name="Visual"
          inputProps={{ "aria-label": "Visual" }}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Product is visible to other exhibitors?
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "8px",
        }}
      >
        <Tooltip title={props.tooltip} placement="right">
          <InfoIcon />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const wait = async function (ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

let validateURL = (url) => {
  if (url === null) {
    url = "";
  }
  let error;
  const urlExpression =
    "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"; //eslint-disable-line
  let regex = new RegExp(urlExpression);
  if (url !== "") {
    if (url.match(regex)) {
      error = "";
    } else {
      error = "Invalid URL";
    }
  }
  return error;
};

const RemoveImageButton = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  if (field.value === null) {
    field.value = "";
  }
  let urlExists = false;
  urlExists = props.currentProduct?.product_image_url && !field.value;
  function RemoveImage() {
    if (urlExists) {
      setValue(props.currentProduct.product_image_url);
    } else {
      setValue("");
    }
  }

  return (
    <Tooltip
      title={urlExists ? "Undo image delete" : "Remove current image."}
      placement="top"
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={() => RemoveImage({ name: "product_image_url" })}
      >
        <Typography variant={urlExists ? "subtitle2" : "body1"}>
          {urlExists ? "Undo" : "X"}
        </Typography>
      </Button>
    </Tooltip>
  );
};

const viewGetImage = async (fullImageUrl, setProductImage) => {
  try {
    if (fullImageUrl.includes("eventhorizon-assets")) {
      const user = await Auth.currentSession();

      let filenamePath = fullImageUrl.split(".com/")[1];

      let dataShape = {
        operation: "getObject",
        file_name: filenamePath,
        bucket_name: "eventhorizon-assets",
      };

      const signedUrl = await axios({
        method: "POST",
        url: "/upload/signed",
        headers: {
          idtoken: user.idToken.jwtToken,
        },
        data: dataShape,
      });

      setProductImage(signedUrl.data);
    } else {
      setProductImage(fullImageUrl);
    }
  } catch (error) {
    console.log(error);
    setProductImage(fullImageUrl);
  }
};

const viewGetFileInNewWindow = async (fullFileUrl) => {
  try {
    if (fullFileUrl.includes("eventhorizon-assets")) {
      const user = await Auth.currentSession();

      let filenamePath = fullFileUrl.split(".com/")[1];

      let dataShape = {
        operation: "getObject",
        file_name: filenamePath,
        bucket_name: "eventhorizon-assets",
      };

      const signedUrl = await axios({
        method: "POST",
        url: "/upload/signed",
        headers: {
          idtoken: user.idToken.jwtToken,
        },
        data: dataShape,
      });

      window.open(signedUrl.data, "_blank");
    } else {
      window.open(getValidUrl(fullFileUrl), "_blank");
    }
  } catch (error) {
    console.log(error);
    window.open(getValidUrl(fullFileUrl), "_blank");
  }
};

export function ProductInfo(props) {
  const [success, setSuccess] = useState(false);
  const [productImage, setProductImage] = useState(null);
  const [file, setFile] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [video, setVideo] = useState(null);
  const classes = useStyles();

  if (props.currentProduct?.product_image_url) {
    if (!productImage) {
      viewGetImage(props.currentProduct.product_image_url, setProductImage);
    }
  }

  const handleFileUpload = (file, meta, status) => {
    setFile(file);
  };

  const handlePdfUpload = (pdf, meta, status) => {
    setPdf(pdf);
  };

  const handleVideoUpload = (pdf, meta, status) => {
    setVideo(pdf);
  };

  let CRUDtype = "put";
  if (!props.currentProduct) {
    CRUDtype = "post";
  }

  let baseProduct = {
    name: "",
    description: "",
    product_url: "",
    product_video_url: "",
    product_document_url: "",
    is_public: false,
    booth_order: null,
    product_image_url: "",
  };

  let viewPdfButton;
  if (props.currentProduct) {
    if (props.currentProduct.product_document_url) {
      viewPdfButton = (
        <Button
          disabled={props.currentProduct.product_document_url.length === 0}
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          onClick={() =>
            viewGetFileInNewWindow(props.currentProduct.product_document_url)
          }
        >
          View PDF
        </Button>
      );
    } else {
      viewPdfButton = (
        <Button
          disabled
          color="primary"
          variant="contained"
          size="large"
          fullWidth
        >
          View PDF
        </Button>
      );
    }
  }
  let viewVideoButton;
  if (props.currentProduct) {
    if (props.currentProduct.product_video_url) {
      // old format, had <a on the outside. Likely had to do with disabled behavior
      // view pdf above had the same format
      // viewVideoButton = (
      //   <a
      //     href={props.currentProduct.product_video_url}
      //     target="_blank"
      //     rel="noopener noreferrer"
      //     style={{ textDecoration: "none" }}
      //   >
      //     <Button
      //       disabled={props.currentProduct.product_video_url.length === 0}
      //       color="primary"
      //       variant="contained"
      //       size="large"
      //       fullWidth
      //     >
      //       View Video
      //     </Button>
      //   </a>
      viewVideoButton = (
        <Button
          disabled={props.currentProduct.product_video_url.length === 0}
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          onClick={() =>
            viewGetFileInNewWindow(props.currentProduct.product_video_url)
          }
        >
          View Video
        </Button>
      );
    } else {
      viewVideoButton = (
        <Button
          disabled
          color="primary"
          variant="contained"
          size="large"
          fullWidth
        >
          View Video
        </Button>
      );
    }
  }

  let onKeyDown = (keyEvent) => {
    if (keyEvent.keyCode === 13) {
      keyEvent.preventDefault();
    }
  };

  return (
    <div className={classes.root}>
      <Formik
        initialValues={
          props.currentProduct ? props.currentProduct : baseProduct
        }
        onSubmit={async (values, { setSubmitting }) => {
          // await new Promise((r) => setTimeout(r, 1000));
          await wait(1000);

          const user = await Auth.currentSession();

          let formatEmail = async (type) => {
            let subject = `${props.event.show_name} - Product ${type}.`;

            let productInformation = {
              productName: values.name,
              description: values.description,
              productUrl: values.product_url,
              productDocumentUrl: values.product_document_url,
              videoUrl: values.product_video_url,
              imageUrl: values.product_image_url,
              isPublic: values.is_public,
              productOrder: values.booth_order,
            };

            let body1 = `Your product has successfully been ${type} for ${props.event.show_name}. Below is the information attached we have received:`;
            let body2 = FormatObject(productInformation);
            // 'If you would now like to submit an abstract for consideration, please revisit ${this.props.event.data.landing_page_url} and click the Abstract Submission Link.';
            // let body3 = `You can access the virtual environment at ${this.props.event.data.landing_page_url}`;
            let body3 = "Enjoy the event!";

            let body = `${body1}<br/>${body2}<br/>${body3}`;

            let emailObject = {
              to: [
                `${props.user.first_name} ${props.user.last_name} <${user.idToken.payload.email}>`,
                "registration@planetconnect.com",
                "rjanelli@planetconnect.com",
              ],
              subject: subject,
              body: body,
              title: productInformation.productName,
              fromName: `${props.event.show_name} Registration`,
              banner: props.eventDetails.banner_location,
            };

            let bodyOfEmail = EmailTemplate(emailObject);
            emailObject.body = bodyOfEmail;
            await axios({
              method: "POST",
              url: `/mail`,
              data: emailObject,
            });
          };

          const functionUploadFile = async (item, fieldName) => {
            try {
              let filenamePath = `${props.show.pharma_company.toLowerCase()}/${
                props.show.show_code
              }/${item.name}`;
              let dataShape = {
                operation: "putObject",
                file_name: filenamePath,
                bucket_name: "eventhorizon-assets",
              };

              const signedUrl = await axios({
                method: "POST",
                url: "/upload/signed",
                headers: {
                  idtoken: user.idToken.jwtToken,
                },
                data: dataShape,
              });

              await fetch(signedUrl.data, {
                method: "PUT",
                body: item,
              });

              values[fieldName] = signedUrl.data.split("?")[0];
            } catch (error) {
              console.log(error);
            }
          };

          if (file) {
            let formData = new FormData();
            formData.append("file", file);

            try {
              await functionUploadFile(file, "product_image_url");
            } catch (error) {
              console.log(error);
            }
          }

          if (pdf) {
            let formData = new FormData();
            formData.append("file", pdf);

            try {
              await functionUploadFile(pdf, "product_document_url");
            } catch (error) {
              console.log(error);
            }
          }

          if (video) {
            let formData = new FormData();
            formData.append("file", video);

            try {
              await functionUploadFile(video, "product_video_url");
            } catch (error) {
              console.log(error);
            }
          }

          if (CRUDtype === "put") {
            const response = await axios({
              method: "put",
              url: `/zoho/products/${props.currentProduct.product_id}`,
              headers: { idtoken: user.idToken.jwtToken },
              data: {
                values,
                showId: props.showId,
                accountId: props.accountId,
                exhibitionId: props.exhibitionId,
                productId: props.currentProduct.product_id,
              },
            });

            if (response.data) {
              await formatEmail("updated");
              // setSuccess(true);
            } else {
              alert("Your product could not be updated");
            }
          } else if (CRUDtype === "post") {
            const response = await axios({
              method: "post",
              url: `/zoho/products`,
              headers: { idtoken: user.idToken.jwtToken },
              data: {
                values,
                showId: props.showId,
                accountId: props.accountId,
                exhibitionId: props.exhibitionId,
              },
            });

            if (response.data) {
              await formatEmail("added");
              // setSuccess(true);
            } else {
              alert("Your product could not be added");
            }
          }

          await wait(3000);

          await setSubmitting(false);
          await setSuccess(true);
        }}
      >
        {({ isSubmitting, values, handleChange }) => (
          <Form className={classes.form} onKeyDown={onKeyDown}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Paper className={classes.paper}>
                  <Typography className={classes.header} variant="h5">
                    Product Information
                  </Typography>
                  <Divider className={classes.divider} />
                  <ProductInfoField
                    name="name"
                    id="name"
                    label="Product Name"
                    type="text"
                    placeholder="Product Name"
                    tooltip="Name of your product."
                    disabled={props.eventIsArchived}
                  />
                  <ProductInfoField
                    name="description"
                    id="description"
                    label="Description"
                    type="text"
                    minRows={5}
                    maxRows={100}
                    multiline
                    placeholder="Description"
                    tooltip="Key information and details about your product."
                    disabled={props.eventIsArchived}
                  />
                  <ProductInfoField
                    name="product_url"
                    id="product_url"
                    label="Product Url"
                    type="text"
                    placeholder="Product Url"
                    tooltip="The URL for the product website or page. This link will be a clickable button in your booth.
                    Note that if you add a PDF via the uploader, the PDF URL will populate this Product URL field. "
                    validate={validateURL}
                  />
                  {/* <ProductInfoField
                    name="product_video_url"
                    id="product_video_url"
                    label="Video Url"
                    type="text"
                    placeholder="Video Url"
                    tooltip="A URL link out to a video you have of this product. URL may be a link to the video hosted on the cloud 
                    or may be hosted on a video streaming site such as vimeo.com or youtube.com."
                    validate={validateURL}
                  />
                  <Typography
                    style={{ marginBottom: "8px" }}
                    variant="subtitle2"
                  >
                    Provide a video link to your product or service here (if
                    applicable)
                  </Typography> */}
                  <Divider className={classes.divider} />
                  <ProductSwitchField
                    name="is_public"
                    id="is_public"
                    label="Is Public"
                    type="text"
                    placeholder="Is Public"
                    tooltip="Toggle on to make your product public and visible to all attendees and exhibitors.
                    Toggle off to make your product only visible to attendees."
                    disabled={props.eventIsArchived}
                  />
                  <ProductInfoField
                    name="booth_order"
                    id="booth_order"
                    label="Product Order"
                    type="number"
                    placeholder="10"
                    tooltip="Products are displayed in the booth based on product
                    order from low to high."
                    disabled={props.eventIsArchived}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid item xs={1} style={{ alignSelf: "center" }}>
                      {values?.product_image_url && (
                        <RemoveImageButton
                          name="product_image_url"
                          currentProduct={props.currentProduct}
                        />
                      )}
                    </Grid>
                    <Grid item xs>
                      <Typography className={classes.header} variant="h5">
                        Current Image
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        title="Upload an image of the product."
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  {values?.product_image_url && (
                    <img
                      // src={values.product_image_url}
                      src={productImage}
                      width="50%"
                      alt="product"
                    />
                  )}
                  <Divider className={classes.divider} />
                  {!props.eventIsArchived ? (
                    <>
                      <Typography
                        className={classes.header}
                        variant="subtitle1"
                      >
                        Update Product Photo:
                      </Typography>
                      <Typography
                        className={classes.header}
                        variant="subtitle2"
                      >
                        Accepts PNG, JPG and GIF. Square aspect ratio such as
                        800px by 800px is recommended. Max size 50mb.
                      </Typography>
                      <FileUploader
                        handleFileUpload={handleFileUpload.bind(this)}
                        acceptedTypes={[".png", ".jpg", ".jpeg", ".gif"]}
                      />
                    </>
                  ) : (
                    <Typography
                      className={classes.header}
                      variant="subtitle2"
                      style={{ visibility: "hidden" }}
                    >
                      A whole bunch of this text is necessary to keep this box
                      happy and the correct size. I'm not sure why. So I hid it.
                    </Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs>
                      <Typography className={classes.header} variant="h5">
                        Current PDF
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        title="Upload a PDF about the product."
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <ProductInfoField
                    name="product_document_url"
                    id="product_document_url"
                    label="Product Document Url"
                    type="text"
                    placeholder="Product Document Url"
                    tooltip="The URL for the product website or page."
                    validate={validateURL}
                    disabled={props.eventIsArchived}
                  />
                  {/* {!props.eventIsArchived && (
                    <Typography
                      style={{ marginBottom: "8px" }}
                      variant="subtitle2"
                    >
                      Uploading a PDF will populate this product URL
                    </Typography>
                  )} */}
                  {viewPdfButton}
                  {!props.eventIsArchived && (
                    <>
                      <Typography
                        className={classes.header}
                        variant="subtitle1"
                      >
                        Upload Product Document:
                      </Typography>
                      <Typography
                        className={classes.header}
                        variant="subtitle2"
                      >
                        Accepts PDF. Max size 50mb.
                      </Typography>
                      <FileUploader
                        handleFileUpload={handlePdfUpload.bind(this)}
                        acceptedTypes={[".pdf"]}
                      />
                    </>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} md={6}>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs>
                      <Typography className={classes.header} variant="h5">
                        Current Linked Video
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip
                        title="Upload a Video about the product."
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <ProductInfoField
                    name="product_video_url"
                    id="product_video_url"
                    label="Video Url"
                    type="text"
                    placeholder="Video Url"
                    tooltip="A URL link out to a video you have of this product. URL may be a link to the video hosted on the cloud 
                    or may be hosted on a video streaming site such as vimeo.com or youtube.com."
                    validate={validateURL}
                    disabled={props.eventIsArchived}
                  />
                  {/* {!props.eventIsArchived && (
                    <Typography
                      style={{ marginBottom: "8px" }}
                      variant="subtitle2"
                    >
                      Provide a video link to your product or service here (if
                      applicable)
                    </Typography>
                  )} */}
                  {viewVideoButton}
                  {!props.eventIsArchived && (
                    <>
                      <Typography
                        className={classes.header}
                        variant="subtitle1"
                      >
                        Upload Video File:
                      </Typography>
                      <Typography
                        className={classes.header}
                        variant="subtitle2"
                      >
                        Accepts Video formats such as mp4, mov, wmv, avi. Max
                        size 50mb.
                      </Typography>
                      <FileUploader
                        handleFileUpload={handleVideoUpload.bind(this)}
                        acceptedTypes={["video/*"]}
                      />
                    </>
                  )}
                </Paper>
              </Grid>
            </Grid>
            {!props.eventIsArchived && (
              <Grid
                item
                xs={10}
                sm={12}
                className={classes.submitGridItem}
                style={{ left: props.sidebar?.open ? "120px" : "2px" }}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  className={classes.submitButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  // style={{ marginTop: '16px', width: '100%', height: '100%',}}
                >
                  <Typography variant="h6">
                    {isSubmitting ? "Saving..." : "Save Changes"}
                  </Typography>
                </Button>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
      <Dialog open={success} handleClose={() => props.handleEditClose()}>
        <Typography variant="h5">Success!</Typography>
        <Typography>Your Product has been updated.</Typography>
        <br />
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={() => props.handleEditClose()}
        >
          X Close
        </Button>
      </Dialog>
    </div>
  );
}
