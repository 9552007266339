import React, { Component } from "react";
import { Auth } from "aws-amplify";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Grid, TextField, Paper, Typography } from "@material-ui/core";
import NavBar from "../../Components/Navbar";
import banner from "../../Assets/Banner.jpg";
import { OktaSSOEnabled, MerckSSOEnabled } from "../../config";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import setAuthenticatedUser from "../../Store/Actions/setUser";

const useStyles = (theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(2),
      width: "100%",
    },
  },
  gridBody: {
    marginTop: "85px",
    justifyContent: "center",
  },
  bannerImage: {
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    position: "relative",
    padding: 0,
    margin: 0,
    // top: "-4px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: "80px",
  },
  formControl: {
    // margin: theme.spacing(2),
    minWidth: 120,
    width: "80%",
  },
  formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: "60%",
  },
  formControlDropdown: {
    // margin: theme.spacing(2),
    minWidth: 120,
    width: "100%",
  },
  button: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: "none",
    },
  },
  toggleButton: {
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.light,
    },
    "& .Mui-selected:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
});

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ssoError: false,
      ssoSignedIn: false,
      ssoEmailChecked: false,
      code: null,
      // global state
      formSubmitted: false,
      globalError: false,
      submissionSuccess: false,
      submissionFailure: false,
      mustConfirmEmail: false,
      justSignedUp: false,
      federatedId: false,
      emailChecked: false,
      existingZohoRegistrant: false,
      existingCognitoUser: false,

      // login
      newPasswordRequired: false,
      newPassword: "",

      // password
      password: "",
      passwordError: null,
      passwordErrorMessage: "",

      // email
      email: "",
      emailError: null,
      emailErrorMessage: "",
    };
  }

  async componentDidMount() {
    let user;
    await Auth.currentSession()
      .then((response) => {
        user = response;
      })
      .catch((error) => {
        console.log(error);
      });
    if (user) {
      await this.ssoCheckRegistrationStatus(user.idToken.payload.email);
    }
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event.target.value });
  }

  handleValidation = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.confirmSignUp(
      this.state.email.toLowerCase(),
      this.state.confirmationCode
    )
      .then((response) => {
        this.setState({
          userConfirmed: true,
          formSubmitted: false,
        });
      })
      .catch((error) => {
        this.setState({
          confirmationCodeError: true,
          confirmationCodeErrorMessage: "Incorrect Confirmation Code",
          formSubmitted: false,
        });
      });
  };

  handleModal(value, inputIdentifier) {
    this.setState({ [inputIdentifier]: value });
  }

  checkEmail = async () => {
    this.setState({
      formSubmitted: true,
      emailError: false,
      emailErrorMessage: "",
      globalError: false,
    });

    if (!this.state.email) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: "Please enter a valid email",
        globalError: true,
      });

      return;
    }

    if (this.state.email.includes("+")) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: "Email cannot contain a '+'",
        globalError: true,
      });

      return;
    }

    if (!this.state.email.includes("@") || !this.state.email.includes(".")) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: "Email must contain: @ and .",
        globalError: true,
      });

      return;
    }

    if (this.state.email.includes(" ")) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage: "Email cannot contain a space",
        globalError: true,
      });

      return;
    }

    let existingZohoRegistrant = false;
    let existingCognitoUser = false;
    // check to see if they are already a cognito user

    const existingRegistrant = await axios({
      method: "GET",
      url: `/attendees/${this.state.email.toLowerCase()}`,
    });

    if (existingRegistrant.data.length) {
      let validAdminRoles = [
        "Event Admin",
        "Host Abstract",
        "Poster Presenter",
        "Booth Admin",
        "Reporting",
        "Host Reporting",
      ];
      existingRegistrant.data.forEach((attendee) => {
        if (attendee?.roles) {
          if (!attendee.roles.includes("Attendee")) {
            existingZohoRegistrant = true;
          }
          validAdminRoles.forEach((potentialRole) => {
            if (attendee.roles.includes(potentialRole)) {
              existingZohoRegistrant = true;
            }
          });
        }
      });
    } else {
      existingZohoRegistrant = false;
    }

    if (!existingZohoRegistrant) {
      this.setState({
        formSubmitted: false,
        emailError: true,
        emailErrorMessage:
          "User does not have admin privileges. Please reach out to your Booth Admin to be added or contact Support below.",
        globalError: true,
      });
      return;
    }

    let isMerckDomain = false;
    const merckDomains = ["@merck.", "@msd."];

    for (let i = 0; i < merckDomains.length; i++) {
      if (this.state.email.toLowerCase().includes(merckDomains[i])) {
        isMerckDomain = true;
      }
    }

    if (isMerckDomain) {
      Auth.federatedSignIn({ provider: "Merck" });

      return;
    }

    if (this.state.email.includes("aapicello@planetconnect.com")) {
      Auth.federatedSignIn({ provider: "Okta" });

      return;
    }

    const code = "000000";

    await Auth.confirmSignUp(this.state.email.toLowerCase(), code, {
      forceAliasCreation: false,
    })
      .then((data) => console.log(data))
      .catch((err) => {
        switch (err.code) {
          case "UserNotFoundException":
            existingCognitoUser = false;
            break;
          case "NotAuthorizedException":
            existingCognitoUser = true;
            break;
          case "AliasExistsException":
            existingCognitoUser = true;
            break;
          case "CodeMismatchException":
            existingCognitoUser = true;
            break;
          case "ExpiredCodeException":
            existingCognitoUser = true;
            break;
          default:
            existingCognitoUser = false;
            break;
        }
      });

    this.setState({
      formSubmitted: false,
      emailChecked: true,
      existingCognitoUser: existingCognitoUser,
      existingZohoRegistrant: existingZohoRegistrant,
    });
  };

  signIn = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.signIn(this.state.email.toLowerCase(), this.state.password)
      .then((response) => {
        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.setState({
            newPasswordRequired: true,
            formSubmitted: false,
          });
        } else {
          //   this.trackSignIn();
          //   this.props.setEventDetails();
          this.props.setAuthenticatedUser();
          //   this.props.setBriefcase();
          this.props.history.push(`/events`);
        }
      })
      .catch((error) => {
        if (error.code === "UserNotConfirmedException") {
          this.setState({
            formSubmitted: false,
            passwordError: true,
            passwordErrorMessage:
              "Please check your email for a confirmation link. If you have not recieved one, please check spam or contact support.",
          });
        } else {
          this.setState({
            formSubmitted: false,
            passwordError: true,
            passwordErrorMessage:
              'Incorrect password. If you forgot your pasword, you may reset it below be clicking on the "Reset Password" button.',
          });
        }
      });
  };

  setPermanentPassword = () => {
    this.setState({
      formSubmitted: true,
    });

    Auth.signIn(this.state.email.toLowerCase(), this.state.password)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(user, this.state.newPassword)
            .then((user) => {
              //   this.props.setEventDetails();
              this.props.setAuthenticatedUser();
              this.props.history.push(`/events`);
            })
            .catch((e) => {
              this.setState({
                emailError: true,
                emailErrorMessage: "Unable to set permanent password",
                formSubmitted: false,
              });
            });
        } else {
          //   this.props.setEventDetails();
          this.props.setAuthenticatedUser();
          this.props.history.push(`/events`);
        }
      })
      .catch((e) => {
        this.setState({
          emailError: true,
          emailErrorMessage: "Unable to set permanent password",
          formSubmitted: false,
        });
      });
  };

  handleCognitoSignup = () => {
    this.setState({
      formSubmitted: true,
      passwordError: false,
      passwordErrorMessage: "",
    });

    Auth.signUp({
      username: this.state.email.toLowerCase(),
      password: this.state.password,
      attributes: {
        email: this.state.email.toLowerCase(),
      },
    })
      .then((response) => {
        this.setState({
          mustConfirmEmail: true,
          existingCognitoUser: true,
          formSubmitted: false,
          justSignedUp: true,
        });
      })
      .catch((error) => {
        switch (error.code) {
          case "UsernameExistsException":
            this.setState({
              emailError: true,
              emailErrorMessage: "User already exists",
              formSubmitted: false,
            });
            break;
          case "InvalidParameterException":
            this.setState({
              emailError: true,
              emailErrorMessage: "Please ensure you are using a valid email",
              formSubmitted: false,
            });
            break;
          default:
            this.setState({
              passwordError: true,
              passwordErrorMessage:
                "Password must be at least 8 characters long.",
              formSubmitted: false,
            });
        }
      });
  };

  handleCountrySelect = (event) => {
    this.setState({
      country: event.target.value,
    });
  };
  handleSiteSelect = (event) => {
    this.setState({
      site: event.target.value,
    });
  };
  handleDepartmentSelect = (event) => {
    this.setState({
      department: event.target.value,
    });
  };

  handleStateSelect = (event) => {
    this.setState({
      state: event.target.value,
    });
  };

  federateSignIn() {
    Auth.federatedSignIn()
      .then((cred) => {
        // If success, you will get the AWS credentials
        return Auth.currentAuthenticatedUser();
      })
      .then((user) => {
        // If success, the user object you passed in Auth.federatedSignIn
      })
      .catch((e) => {
        console.log(e);
      });
  }

  ssoSignedInNavigation = async () => {
    // await this.trackSignIn();
    await this.props.setAuthenticatedUser();
    this.props.history.push("/events");
    window.location.reload();
  };

  ssoCheckRegistrationStatus = async (email) => {
    this.setState({
      formSubmitted: false,
      ssoEmailChecked: true,
      existingZohoRegistrant: true,
      ssoSignedIn: true,
      email: email,
    });
  };

  render() {
    const { classes } = this.props;

    if (this.state.ssoError) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              className={classes.gridBody}
              container
              layout={"row"}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <img src={banner} alt="Lobby" className={classes.bannerImage} />
                <Paper className={classes.paper}>
                  <Typography variant="h5">
                    There's been an error logging you in.
                  </Typography>
                  <Typography variant="body1">
                    Please ensure you are on your company's VPN. If the problem
                    persists, please contact support.
                  </Typography>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={() => this.setState({ ssoError: false })}
                      className={classes.button}
                    >
                      Return to Login
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    if (this.state.ssoSignedIn) {
      if (this.state.existingZohoRegistrant) {
        return (
          <div>
            <NavBar />
            <div className={classes.grid}>
              <Grid
                className={classes.gridBody}
                container
                layout={"row"}
                justifyContent="center"
                spacing={0}
              >
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <img
                    src={banner}
                    alt="Lobby"
                    className={classes.bannerImage}
                  />
                  <Paper className={classes.paper}>
                    <Typography variant="h5">You're all set!</Typography>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        variant="contained"
                        onClick={() => this.ssoSignedInNavigation()}
                        className={classes.button}
                      >
                        Enter Admin Panel
                      </Button>
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    if (this.state.newPasswordRequired) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              className={classes.gridBody}
              container
              layout={"row"}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <img src={banner} alt="Lobby" className={classes.bannerImage} />
                <Paper className={classes.paper}>
                  <Typography variant="h5">
                    Create Permanent Password
                  </Typography>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      onChange={(event) => this.handleChange(event, "email")}
                      value={this.state.email}
                      label="Email"
                      error={this.state.emailError}
                    />
                  </FormControl>
                  <FormControl className={classes.formControl} fullWidth>
                    <TextField
                      onChange={(event) =>
                        this.handleChange(event, "newPassword")
                      }
                      type="password"
                      value={this.state.newPassword}
                      label="Password"
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.setPermanentPassword}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Submit</div>
                      )}
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }

    if (this.state.emailChecked) {
      if (!this.state.existingCognitoUser) {
        return (
          <div>
            <NavBar />
            <Grid
              style={{ paddingTop: "85px", justifyContent: "center" }}
              container
              layout={"row"}
              justifyContent="center"
              spacing={0}
            >
              <Grid item xs={12} sm={8} md={6}>
                <img src={banner} alt="Lobby" className={classes.bannerImage} />
                <Paper className={classes.paper}>
                  <br />
                  <Typography variant="h5">
                    Perfect! Now let's make sure you can securely login
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    Please create a password using the form below
                  </Typography>
                  <br />
                  {/* <FormControl className={classes.formControl}>
                        <TextField
                            onChange={(event) => this.handleChange(event, "username")}
                            value={this.state.username}
                            label="Username"
                            fullWidth
                            error = {this.state.usernameError}
                            helperText={this.state.usernameError ? this.state.usernameErrorMessage : "Username cannot contain spaces"}
                        />
                    </FormControl> */}
                  <FormControl
                    className={classes.formControl}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") this.handleCognitoSignup();
                    }}
                  >
                    <TextField
                      onChange={(event) => this.handleChange(event, "email")}
                      value={this.state.email}
                      label="Email"
                      disabled
                      error={this.state.emailError}
                      helperText={this.state.emailErrorMessage}
                    />
                    <br />
                    <TextField
                      type="password"
                      onChange={(event) => this.handleChange(event, "password")}
                      value={this.state.password}
                      label="Password"
                      error={this.state.passwordError}
                      helperText={
                        this.state.passwordErrorMessage
                          ? this.state.passwordErrorMessage
                          : "Password must be at least 8 characters long"
                      }
                      autoFocus
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.handleCognitoSignup}
                      className={classes.button}
                    >
                      {this.state.formSubmitted ? (
                        <CircularProgress color="inherit" />
                      ) : (
                        <div>Next</div>
                      )}
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        );
      } else {
        return (
          <div>
            <NavBar />
            <div className={classes.grid}>
              <Grid
                className={classes.gridBody}
                container
                layout={"row"}
                justifyContent="center"
                spacing={0}
              >
                <Grid item xs={12} sm={8} md={6}>
                  <img
                    src={banner}
                    alt="Lobby"
                    className={classes.bannerImage}
                  />
                  <Paper className={classes.paper}>
                    {this.state.justSignedUp ? (
                      <React.Fragment>
                        <br />
                        <Typography variant="h5">
                          Beautiful! One last step.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                          Check your inbox for an email from
                          eregistration@planetconnect.com for your verification
                          link, then return here to sign in. The verification
                          link will only be valid for 1 hour.
                        </Typography>
                        <br />
                        {/* <FormControl className={classes.formControlButton}>
                                        <Button variant="contained" onClick={() => this.resendVerificationCode()} className={classes.button}>
                                            Resend Verification
                                        </Button>
                                    </FormControl> */}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <br />
                        <Typography variant="h5">
                          Please input your password below to sign in.
                        </Typography>
                        <br />
                        <Typography variant="body1">
                          If you have forgotten your password, you may reset it
                          below.
                        </Typography>
                        <br />
                      </React.Fragment>
                    )}

                    <FormControl
                      className={classes.formControl}
                      fullWidth
                      onKeyUp={(event) => {
                        if (event.key === "Enter") this.signIn();
                      }}
                    >
                      <TextField
                        onChange={(event) => this.handleChange(event, "email")}
                        value={this.state.email}
                        label="Email"
                        error={this.state.emailError}
                        disabled
                      />
                      <br />
                      <TextField
                        onChange={(event) =>
                          this.handleChange(event, "password")
                        }
                        type="password"
                        value={this.state.password}
                        label="Password"
                        error={this.state.passwordError}
                        helperText={this.state.passwordErrorMessage}
                        autoFocus
                      />
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        variant="contained"
                        onClick={this.signIn}
                        className={classes.button}
                      >
                        {this.state.formSubmitted ? (
                          <CircularProgress color="inherit" />
                        ) : (
                          <div>Enter</div>
                        )}
                      </Button>
                    </FormControl>
                    <FormControl className={classes.formControlButton}>
                      <Button
                        color="inherit"
                        component={Link}
                        to={"/forgotpassword"}
                      >
                        Reset Password
                      </Button>
                    </FormControl>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        );
      }
    }

    return (
      <div>
        <NavBar />
        <Grid
          style={{ paddingTop: "85px", justifyContent: "center" }}
          container
          layout={"row"}
          justifyContent="center"
          align="center"
          spacing={0}
        >
          <Grid item xs={12} sm={8} md={6}>
            <img src={banner} alt="Lobby" className={classes.bannerImage} />
            <Paper className={classes.paper}>
              <br />
              <Typography variant="h5">
                Welcome to the Admin Panel!
                {/* Welcome to the {this.props.event.event.title}! */}
              </Typography>
              <br />
              <Typography variant="body1">
                Let's get started! Please provide your email address below and
                click next.
              </Typography>
              <br />
              <FormControl
                className={classes.formControl}
                onKeyUp={(event) => {
                  if (event.key === "Enter") this.checkEmail();
                }}
              >
                <TextField
                  required
                  onChange={(event) => this.handleChange(event, "email")}
                  value={this.state.email}
                  label="Email"
                  error={this.state.emailError}
                  helperText={this.state.emailErrorMessage}
                  autoFocus
                />
              </FormControl>
              <FormControl className={classes.formControlButton}>
                <br />
                <Button
                  type="submit"
                  variant="contained"
                  onClick={this.checkEmail}
                  className={classes.button}
                >
                  {this.state.formSubmitted ? (
                    <CircularProgress color="inherit" />
                  ) : (
                    <div>Next</div>
                  )}
                </Button>
                <br />
                {MerckSSOEnabled ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() =>
                        Auth.federatedSignIn({ provider: "Merck" })
                      }
                      className={classes.button}
                    >
                      Login with Merck
                    </Button>
                    <br />
                  </>
                ) : null}
                {OktaSSOEnabled ? (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => Auth.federatedSignIn({ provider: "Okta" })}
                      className={classes.button}
                    >
                      Login with Okta
                    </Button>
                    <br />
                  </>
                ) : null}
              </FormControl>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setAuthenticatedUser: setAuthenticatedUser,
      //   setBriefcase: setBriefcase,
      //   setEventDetails: setEventDetails,
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(useStyles)(SignUpForm)));
