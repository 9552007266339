import React, { Component } from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import { ColorPicker } from "../Shared/ColorPicker";
import Dialog from "../../Components/Dialog";
import { Link } from "react-router-dom";
import { Formik, Form, Field, useField, FieldArray } from "formik";
import InfoIcon from "@material-ui/icons/Info";
import { FileUploader } from "../Shared/FileUploader";

// Configure Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import removeAuthenticatedUser from "../../Store/Actions/removeUser";
import removeEvent from "../../Store/Actions/removeEvent";

import ForceLogOut from "../Shared/ForceLogOut";

const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing(12),
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: "center",
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  center: {
    textAlign: "center",
    padding: theme.spacing(2),
    // marginBottom: theme.spacing(3),
  },
  submitGridItem: (props) => ({
    position: "fixed",
    bottom: "60px",
    left: props.sidebar?.open ? "120px" : "2px",
    width: "100%",
    zIndex: 1,
    textAlign: "center",
    background: theme.palette.background.main,
    transition: theme.transitions.create(["left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    borderStyle: "solid",
    borderColor: theme.palette.background.dark,
  }),
  submitButton: (props) => ({
    color: "white",
    padding: theme.spacing(2, 8),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  }),
  boothColorBlocks: {
    display: "grid",
    alignContent: "space-between",
  },
});

const CompanyInfoField = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <Field> and alse replace ErrorMessage entirely.x
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      setValue(e.target.value + "\r\n");
    }
  };

  return (
    <Grid container>
      <Grid item xs>
        <Field
          className="text-input"
          component={TextField}
          margin="dense"
          variant="outlined"
          fullWidth
          onKeyDown={props.minRows ? handleOnKeyDown : null}
          {...field}
          {...props}
        />
        {meta.touched && meta.error ? (
          <div className="error" style={{ color: "red" }}>
            {meta.error}
          </div>
        ) : null}
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Tooltip title={props.tooltip} placement="right">
          <InfoIcon />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const CompanyTagField = ({ ...props }) => {
  // const classes = useStyles();
  // const [tags, setTags] = React.useState([]);
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  let tags = [];
  tags = props.values.tags;

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13) {
      props.values.tags = [...tags, e.target.value];
      setValue("");
    }
  };

  return (
    <Box
      component="ul"
      style={{
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        padding: "2px",
        margin: 0,
      }}
    >
      <Field
        component={TextField}
        // type="text"
        margin="dense"
        variant="outlined"
        fullWidth
        onKeyDown={handleOnKeyDown}
        {...field}
        {...props}
      />
    </Box>
  );
};

const CompanyChips = ({ ...props }) => {
  // const classes = useStyles();
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  let tags = props.values.tags;

  const handleDelete = (e) => {
    tags = tags.filter((tag) => {
      return tag !== e;
    });
    props.values.tags = tags;
    setValue(tags);
  };

  let tagsMapped;
  if (tags) {
    tagsMapped = tags.map((data, index) => {
      return (
        <li key={index}>
          <Chip
            label={data}
            style={{ margin: "2px" }}
            color="primary"
            onDelete={() => handleDelete(data)}
            // onDelete={data === "React" ? undefined : handleDelete(data)} // working method from example: https://codesandbox.io/s/material-demo-forked-2uggu?file=/demo.js
          />
        </li>
      );
    });
  } else {
    tagsMapped = null;
  }

  return (
    <Box
      component="ul"
      style={{
        display: "flex",
        flexWrap: "wrap",
        listStyle: "none",
        padding: "2px",
        margin: 0,
      }}
    >
      {tagsMapped}
    </Box>
  );
};

const CompanySwitchField = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;

  if (field.value === null) {
    field.value = "";
  }

  const handleVisualToggle = () => {
    setValue(!field.value);
  };

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Field
          component={Switch}
          checked={field.value}
          onChange={handleVisualToggle}
          color="primary"
          name="Visual"
          inputProps={{ "aria-label": "Visual" }}
        />
      </Grid>
      <Grid item>
        <Typography variant="body1">Visible to other exhibitors?</Typography>
      </Grid>
      <Grid
        item
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "8px",
        }}
      >
        <Tooltip title={props.tooltip} placement="right">
          <InfoIcon />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

const CompanyColorPicker = ({ ...props }) => {
  const [field, meta, helpers] = useField(props); //eslint-disable-line
  const { setValue } = helpers;
  if (field.value === null) {
    field.value = "";
  }
  return (
    <React.Fragment>
      {!props.eventIsArchived && (
        <ColorPicker
          color={field.value}
          onChange={(chosenColor) => {
            setValue(chosenColor.hex);
          }}
        />
      )}
      <Field
        component={TextField}
        // name={label.replace(/ +/g, "")}
        type="text"
        // label={label}
        // value={color}
        margin="dense"
        variant="outlined"
        fullWidth
        disabled
        {...field}
        {...props}
      />
    </React.Fragment>
  );
};

class CompanyInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: null,
      show: null,
      success: null,
      tagFieldFocused: false,
      file: null,
    };
  }

  async componentDidMount() {
    if (this.props.event.event !== null) {
      await this.getCompany();
      await this.getShow();
    }
  }

  async getCompany() {
    try {
      await this.setState({
        company: null,
      });

      Auth.currentSession().then((data) => {
        axios({
          method: "get",
          url:
            `/exhibitions/` +
            this.props.event.event.show_id +
            "/" +
            this.props.user.user.account_id +
            "/" +
            this.props.event.event.exhibition_id,
          headers: { idtoken: data.idToken.jwtToken },
        })
          .then((response) => {
            if (
              response.data[0] === undefined ||
              response.data[0].length === 0
            ) {
              return null;
            } else if (!response.data[0].linked_in_tags) {
              response.data[0].linked_in_tags = "";
            } else {
              response.data[0].linked_in_tags =
                response.data[0].linked_in_tags.split(",");
            }

            this.setState({
              company: response.data[0],
            });
            // console.log(this.state.company)
          })
          .catch((error) => {
            console.log(error);
          });
      });
    } catch (error) {
      if (error === "No current user") {
        console.log(error, "log them out");
        try {
          ForceLogOut(
            this.props.removeEvent,
            this.props.removeAuthenticatedUser
          );
        } catch (error) {
          console.log("ForceLogOut", error);
        }
      }
      console.log(error);
    }
  }

  async getShow() {
    const data = await Auth.currentSession();

    let myShow = await axios({
      method: "get",
      url: `/shows/${this.props.event.event.show_id}`,
      headers: { idtoken: data.idToken.jwtToken },
    });
    myShow = myShow.data[0];
    await this.setState({ show: myShow });
  }

  async wait(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.timestamp === nextProps.timestamp) {
      return true;
    } else {
      this.getCompany();
      return false;
    }
  }

  tagFieldFocus = () => {
    this.setState({
      tagFieldFocused: true,
    });
  };

  tagFieldBlur = () => {
    this.setState({
      tagFieldFocused: false,
    });
  };

  onKeyDown = (keyEvent) => {
    if (keyEvent.keyCode === 13) {
      keyEvent.preventDefault();
    }
  };

  handleFileUpload = (file, meta, status) => {
    this.setState({ file });
  };

  render() {
    const { classes } = this.props;

    let eventIsArchived = false;
    if (this.props.archived.archived) {
      if (!this.props.user.user.email.includes("rjanelli@planetconnect.com")) {
        eventIsArchived = true;
      }
    }

    let validateURL = (url) => {
      let error;
      const urlExpression =
        "^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$"; //eslint-disable-line
      let regex = new RegExp(urlExpression);
      if (url !== "") {
        if (url.match(regex)) {
          error = "";
        } else {
          error = "Invalid URL";
        }
      }
      return error;
    };

    if (this.props.event.event === null) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Button component={Link} to={`/events`} color="secondary">
                  Select Event
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    }

    // let bodyContent;

    if (!this.state.company) {
      return (
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Paper>
                <Typography className={classes.center} variant="h4">
                  Company Information
                </Typography>
              </Paper>
              <Typography className={classes.center} variant="h5">
                We have your account but your booth is not set up yet.
                <br />
                Please reach out to PlanetConnect for assistance.
              </Typography>
            </Grid>
          </Grid>
        </div>
      );
    }

    let levelAsString = "";
    switch (this.state.company.booth_type) {
      case "0":
        levelAsString = "Signature";
        break;
      case "1":
        levelAsString = "Diamond";
        break;
      case "2":
        levelAsString = "Platinum";
        break;
      case "3":
        levelAsString = "Gold";
        break;
      case "4":
        levelAsString = "Silver";
        break;
      default:
        levelAsString = "Booth";
    }

    return (
      <div className={classes.root}>
        <Formik
          initialValues={{
            companyNameForBooth: this.state.company.account_alias,
            level: levelAsString,
            companyUrl: this.state.company.url,
            sponsorshipLevel: this.state.company.level_requested,
            companyDescription: this.state.company.main_text,
            currentTag: "",
            tags: this.state.company.linked_in_tags,
            primaryColor: this.state.company.primary_color,
            secondaryColor: this.state.company.secondary_color,
            logo_address: this.state.company.logo_address, //snake case for both is correct
            isPublic: this.state.company.is_public,
            meetingRoom: this.state.company.meeting_room,
            meetingRoomHost: this.state.company.meeting_room_host_link,
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await new Promise((r) => setTimeout(r, 500));

            const user = await Auth.currentSession();

            if (this.state.file) {
              let formData = new FormData();
              formData.append("file", this.state.file);

              try {
                let filenamePath = `${this.state.show.pharma_company.toLowerCase()}/${
                  this.state.show.show_code
                }/${this.state.file.name}`;
                let dataShape = {
                  operation: "putObject",
                  file_name: filenamePath,
                  bucket_name: "eventhorizon-assets-public",
                };

                const signedUrl = await axios({
                  method: "POST",
                  url: "/upload/signed",
                  headers: {
                    idtoken: user.idToken.jwtToken,
                  },
                  data: dataShape,
                });

                await fetch(signedUrl.data, {
                  method: "PUT",
                  body: this.state.file,
                });
                values.logo_address = signedUrl.data.split("?")[0];
              } catch (error) {
                console.log(error);
              }
            }

            if (typeof values.tags === "string") {
              // tags as string causes zoho update to fail
              values.tags = [];
            }

            const response = await axios({
              method: "PUT",
              url: `/zoho/exhibitions/${this.props.event.event.show_id}/${this.props.user.user.account_id}/${this.state.company.exhibition_id}`,
              headers: { idtoken: user.idToken.jwtToken },
              data: {
                values,
                linked_in_tags: values.tags.join(","),
                id: this.state.company.exhibition_id,
              },
            });

            await this.wait(3000);

            if (response.data) {
              this.setState({
                success: true,
              });
            } else {
              alert("Your company information could not be updated");
            }

            await setSubmitting(false);
            await this.getCompany();
          }}
        >
          {({ isSubmitting, values, handleChange }) => (
            <Form onKeyDown={this.onKeyDown}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Paper>
                    <Typography className={classes.center} variant="h4">
                      Company Information
                    </Typography>
                    {eventIsArchived && (
                      <Typography
                        className={classes.center}
                        variant="body1"
                        color="error"
                      >
                        Event is archived and cannot be edited.
                      </Typography>
                    )}
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.header} variant="h5">
                      About
                    </Typography>
                    <Divider className={classes.divider} />
                    <CompanyInfoField
                      name="companyNameForBooth"
                      id="companyNameForBooth"
                      label="Company Name for Booth"
                      type="text"
                      placeholder="Company Name for Booth"
                      tooltip="Name to display for your booth. You may make it different from your company name."
                      required
                      disabled={eventIsArchived}
                    />
                    <CompanyInfoField
                      name="level"
                      id="level"
                      label="Level"
                      type="text"
                      placeholder="Level"
                      tooltip="The sponsorship level your booth is."
                      disabled
                    />
                    <CompanyInfoField
                      name="companyUrl"
                      id="companyUrl"
                      label="Company URL"
                      type="text"
                      placeholder="Your Company URL"
                      tooltip="The URL of your company."
                      validate={validateURL}
                      disabled={eventIsArchived}
                    />
                    <CompanyInfoField
                      name="companyDescription"
                      id="companyDescription"
                      label="Company Description"
                      type="text"
                      placeholder="Information about your company"
                      tooltip="The description of your company that will display in the booth overview."
                      minRows={5}
                      maxRows={100}
                      multiline
                      onKeyPress={(e) => {
                        e.which === 13 && e.preventDefault();
                      }}
                      disabled={eventIsArchived}
                    />
                    <Divider className={classes.divider} />
                    <Grid container>
                      <Grid item xs={12} md={9} lg={6}>
                        <CompanySwitchField
                          name="isPublic"
                          id="isPublic"
                          label="Is Public"
                          type="text"
                          placeholder="Is Public"
                          tooltip="Toggle on if you would like to make your booth public to all attendee and exhibitors. Toggle off to make it private so other exhibitors cannot see it."
                        />
                      </Grid>
                      <Grid item xs={12} md={3} lg={6} />
                    </Grid>
                    <CompanyInfoField
                      name="meetingRoom"
                      id="meetingRoom"
                      label="Meeting Room Link"
                      type="text"
                      placeholder="Meeting Room Link"
                      tooltip="The link attached the the meet me button in your booth. Gives attendees the ability to hop into a video call with you."
                      disabled
                    />
                    <CompanyInfoField
                      name="meetingRoomHost"
                      id="meetingRoomHost"
                      label="Meeting Room HostLink"
                      type="text"
                      placeholder="Meeting Room Host Link"
                      tooltip="The host specific link for extra video call privileges."
                      disabled
                    />
                  </Paper>
                  <Paper className={classes.paper}>
                    <Grid container>
                      <Grid item xs={1} />

                      <Grid item xs>
                        <Typography className={classes.header} variant="h5">
                          Keyword Tags
                          <br /> (press Enter to add tags)
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Tooltip
                          title="Keywords that will show up in your booth overview section."
                          placement="right"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <FieldArray
                      name="tagFields"
                      render={() => {
                        return (
                          <>
                            <CompanyTagField
                              name="currentTag"
                              id="currentTag"
                              label="Input Your Company Tags"
                              values={values}
                              onFocus={this.tagFieldFocus}
                              onBlur={this.tagFieldBlur}
                              disabled={eventIsArchived}
                            />
                            <CompanyChips
                              name="tags"
                              id="tags"
                              label="Tags"
                              values={values}
                            />
                          </>
                        );
                      }}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper className={classes.paper}>
                    <Grid container>
                      <Grid item xs={1} />
                      <Grid item xs>
                        <Typography className={classes.header} variant="h5">
                          Company Logo
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Tooltip
                          title="Upload your company logo here. Either drag and drop the image into the box below or click the box to navigate and find the file."
                          placement="right"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    {this.state.company.logo_address ? (
                      <img
                        src={this.state.company.logo_address}
                        alt="logo"
                        width="50%"
                      />
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    <Divider className={classes.divider} />
                    {!eventIsArchived && (
                      <>
                        <Typography className={classes.header} variant="body1">
                          Update Logo:
                        </Typography>
                        <Typography
                          className={classes.header}
                          variant="subtitle2"
                        >
                          Accepts PNG, JPG and GIF. Square aspect ratio such as
                          800px by 800px is recommended. Max size 50mb.
                        </Typography>
                        <FileUploader
                          handleFileUpload={this.handleFileUpload.bind(this)}
                          acceptedTypes={[".png", ".jpg", ".jpeg", ".gif"]}
                        />
                      </>
                    )}
                  </Paper>
                  <Paper className={classes.paper}>
                    <Grid container>
                      <Grid item xs={1} />
                      <Grid item xs>
                        <Typography className={classes.header} variant="h5">
                          Company Colors
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Tooltip
                          title="Select your booth colors via the color picker or enter a HEX or RGB value. Colors pull through and display in the booth image."
                          placement="right"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.boothColorBlocks}
                      >
                        <Typography
                          className={classes.header}
                          variant="subtitle2"
                        >
                          Main color.
                        </Typography>
                        <CompanyColorPicker
                          name="primaryColor"
                          id="primaryColor"
                          label="Primary Color"
                          disabled={eventIsArchived}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.boothColorBlocks}
                      >
                        <Typography
                          className={classes.header}
                          variant="subtitle2"
                        >
                          Secondary color.
                        </Typography>
                        <CompanyColorPicker
                          name="secondaryColor"
                          id="secondaryColor"
                          label="Secondary Color"
                          disabled={eventIsArchived}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                {!eventIsArchived && (
                  <Grid
                    item
                    xs={12}
                    className={classes.submitGridItem}
                    style={{ padding: 0 }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className={classes.submitButton}
                      color="primary"
                      variant="contained"
                      size="large"
                    >
                      <Typography variant="h6">
                        {isSubmitting ? "Saving..." : "Save Changes"}
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
        <Dialog
          open={this.state.success ? this.state.success : false}
          handleClose={() => this.setState({ success: null })}
        >
          <Typography variant="h5">Success!</Typography>
          <Typography>Your Company Info has been updated.</Typography>
          <br />
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={() => this.setState({ success: null })}
          >
            X Close
          </Button>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
    sidebar: state.sidebar,
    archived: state.archived,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      removeAuthenticatedUser: removeAuthenticatedUser,
      removeEvent: removeEvent,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(CompanyInfo));
